/* eslint-disable */

// fieldType
export const FIELD_TYPE_INPUT = 1 // 文本框
export const FIELD_TYPE_SELECT = 2 // 下拉
export const FIELD_TYPE_URL = 3 // 图像
export const FIELD_TYPE_TEXTAREA = 4 // 多文本框
export const FIELD_TYPE_DIC = [
  {
    label: '单文本框',
    value: FIELD_TYPE_INPUT,
    localValue: 'input'
  },
  {
    label: '下拉',
    value: FIELD_TYPE_SELECT,
    localValue: 'select'
  },
  {
    label: '图像',
    value: FIELD_TYPE_URL,
    localValue: 'image'
  },
  {
    label: '多文本框',
    value: FIELD_TYPE_TEXTAREA,
    localValue: 'textarea'
  } 
]

// 亚马逊文本格式类型
export const AMAZON_TYPE_NOT_LIMITED = 0 //不限制
export const AMAZON_TYPE_INTEGER = 1 //整数
export const AMAZON_TYPE_DECIMAL = 2 //小数
export const AMAZON_TYPE_DATA = 3 //日期
export const AMAZON_TEXT_TYPE_DIC =[
  {
    label: '不限制',
    value: AMAZON_TYPE_NOT_LIMITED,
    localValue: 'input'
  },
  {
    label: '整数',
    value: AMAZON_TYPE_INTEGER,
    localValue: 'number'
  },
  {
    label: '小数(精确到0.01)',
    value: AMAZON_TYPE_DECIMAL,
    localValue: 'number'
  },
  {
    label: '日期 yyyy-MM-dd',
    value: AMAZON_TYPE_DATA,
    localValue: 'data'
  },
]

// 速卖通-样式选择
export const ALI_TEXT_BOX_INPUT = 1 //文本框
export const ALI_DROP_DOWN_MENU = 2 // valid_values下拉菜单
export const ALI_IMG = 3 //图像url
export const ALI_NUM_INPUT = 4 //数字输入框
export const ALI_EXPRESS_TYPE_DIC =[
  {
    label: '文本框',
    value: ALI_TEXT_BOX_INPUT,
    localValue: 'input'
  },
  {
    label: '下拉',
    value: ALI_DROP_DOWN_MENU,
    localValue: 'select'
  },
  {
    label: '图像',
    value: ALI_IMG,
    localValue: 'image'
  },
  {
    label: '数字输入框',
    value: ALI_NUM_INPUT,
    localValue: 'number'
  } 
]

// 速卖通单位
export const ALI_UNIT_OPTION = [
  {
    label: '厘米',
    value: '1'
  },
  {
    label: '公斤',
    value: '2'
  },
  {
    label: '天',
    value: '3'
  },
  {
    label: '美元',
    value: '4'
  },
]

// columnGroupType
export const COLUMN_GROUP_TYPE_DIC = [
  {
    label: 'Required',
    value: 1
  },
  {
    label: 'Images',
    value: 2
  },
  {
    label: 'Variation',
    value: 3
  },
  {
    label: 'Basic',
    value: 4
  },
  {
    label: 'Discovery',
    value: 5
  },
  {
    label: 'Product Enrichment',
    value: 6
  },
  {
    label: 'Dimensions',
    value: 7
  },
  {
    label: 'Fulfillment',
    value: 8
  },
  {
    label: 'Compliance',
    value: 9
  },
  {
    label: 'Offer',
    value: 10
  },
  {
    label: 'B2B',
    value: 11
  }
]

export const TYPE_ALIEXPRESS_NUM = 1 //速卖通-code
export const TYPE_AMAZON_NUM = 2 //亚马逊-code
export const TYPE_EBAY_NUM = 3 // ebay
export const TYPE_IS_NUM = 5 // 国际站
export const TYPE_OTHER_NUM = 4 // 其他


// templateType  0-系统模板 1-用户模板 2-自定义模板
export const TEMPLATE_TYPE_SYS = 0
export const TEMPLATE_TYPE_USER = 1
export const TEMPLATE_TYPE_CUSTOM = 2